import { AppRoutes } from "enums/routes.enum";

export const FOOTER_EXCLUDED_PATHS = [
    AppRoutes.SHARE_NON_LEGAL,
    AppRoutes.SHARE_LEGAL,
    AppRoutes.RESTRICTED_ACCESS,
    AppRoutes.PRIVACY_POLICY,
    AppRoutes.COOKIE_POLICY,
    AppRoutes.TERMS_OF_USE,
    AppRoutes.FIND_DEVICE,
    AppRoutes.LOST_PHONE,
    AppRoutes.GENERATE_LINK,
    AppRoutes.OAUTH,
];
