import { useLayoutEffect, useState } from "react";
import { fetchAndActivate, getAll, getRemoteConfig, isSupported } from "firebase/remote-config";
import { app } from "services/firebase";

export const useFbConfigData = () => {
    const [abTestData, setAbTestData] = useState<Record<string, string> | undefined>();
    const [allAbTestFlags, setAllAbTestFlags] = useState<Record<string, any> | undefined>();
    const [remoteConfigData, setRemoteConfigData] = useState<Record<string, any> | null>(null);

    useLayoutEffect(() => {
        (async () => {
            try {
                const remoteConfigIsSupported = await isSupported();

                if (!remoteConfigIsSupported) {
                    throw new Error();
                }

                if (remoteConfigIsSupported) {
                    const remoteConfig = getRemoteConfig(app);
                    remoteConfig.settings.minimumFetchIntervalMillis = 3600000; /* 1 hour */

                    await fetchAndActivate(remoteConfig);
                    const allAbTestFlags = getAll(remoteConfig);

                    const allFlagValues = Object.keys(allAbTestFlags).reduce((acc, key) => {
                        acc[key] = allAbTestFlags[key]["_value"];
                        return acc;
                    }, {});
                    setAllAbTestFlags(allFlagValues);

                    const abTestPaymentScreenOptionsNonLegal = allAbTestFlags["payment_screen_options_non_legal"]?.asString();
                    const abTestPaymentScreenOptionsLegal = allAbTestFlags["payment_screen_options_legal"]?.asString();
                    const abTestPaywall = allAbTestFlags["ab_paywall"]?.asString();
                    const abTestAuth = allAbTestFlags["ab_auth"]?.asString();
                    const abTestLanding = allAbTestFlags["ab_landing"]?.asString();
                    const abTestContact = allAbTestFlags["ab_contact"]?.asString();
                    const abTestApplePay = allAbTestFlags["ab_applepay"]?.asString();
                    const abOffer = allAbTestFlags["ab_offer"]?.asString();
                    const abCurrency = allAbTestFlags["ab_currency"]?.asString();
                    const abTestGooglePay = allAbTestFlags["ab_googlepay"]?.asString();

                    setAbTestData((prevState) => {
                        return {
                            ...(prevState || {}),
                            abTestPaymentScreenOptionsNonLegal,
                            abTestPaymentScreenOptionsLegal,
                            abTestPaywall,
                            abTestAuth,
                            abTestLanding,
                            abTestContact,
                            abTestApplePay,
                            abTestGooglePay,
                            abOffer,
                            abCurrency,
                        };
                    });

                    const legalAddressConfig = JSON.parse(allAbTestFlags["legal_address_config"]?.asString());

                    setRemoteConfigData((prevState) => {
                        return {
                            ...prevState,
                            legalAddressConfig,
                        };
                    });
                }
            } catch (err) {
                console.error("Error setting firebase config: ", (err as any)?.message);
            }
        })();
    }, []);

    return { abTestData, allAbTestFlags, remoteConfigData };
};
