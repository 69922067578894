import { PaywallVariants } from "enums/PaywallVariants";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { useState } from "react";
import { planVariantsV3, planVariantsV3_7d } from "../constants";

const defaultPlanData = {
    trial7days: {
        title: "7-day trial",
        oldPrice: 9.99,
    },
    trial24h: {
        title: "24h Trial",
        oldPrice: 9.99,
    },
};

export const usePaymentPlanVariants = (plans) => {
    const { abTestData } = useAppData();
    const { userWebSession } = useUserStore();
    const abTestPaywall = abTestData?.abTestPaywall || userWebSession?.session?.abTestData?.abTestPaywall;
    const is7DaysPlanVariant = abTestPaywall === PaywallVariants.MapV3WithWeekPlan;

    const planVariants = is7DaysPlanVariant ? planVariantsV3_7d : planVariantsV3;
    const defaultPlanExtraData = is7DaysPlanVariant ? defaultPlanData.trial7days : defaultPlanData.trial24h;
    const planMatchAmplitudeId = is7DaysPlanVariant ? "30days_59.99$_with_trial_7days_4.99$" : "1month_49.99$_with_trial_1day_4.99$";

    const defaultPlan = plans?.find((plan) => plan.amplitudeId === planMatchAmplitudeId);

    const [chosenPlan, setChosenPlan] = useState(defaultPlan);
    const [planExtraData, setPlanExtraData] = useState(defaultPlanExtraData);

    return {
        chosenPlan,
        setChosenPlan,
        planVariants,
        defaultPlan,
        defaultPlanExtraData,
        planExtraData,
        setPlanExtraData,
    };
};
