import { styled } from "@mui/material";
import { textFieldProps } from "components/form-components/TextField/TextField";
import { IxopayFormTextField } from "./IxopayFormTextField";

export const BorderedGreyTextField = styled(IxopayFormTextField)(({ theme }) => ({
    ...textFieldProps,
    border: "none",
    color: theme.palette.text.secondary,
    "& .MuiInputBase-root": {
        height: "50px",
        padding: 0,
        borderRadius: "8px",
        border: `1px solid ${theme.palette.background.borderLightGrey} !important`,
        background: "rgba(45, 49, 55, 0.40)",
        "& input": {
            padding: "16px",
            "&::placeholder": {
                fontSize: "14px",
                color: "rgba(255,255,255, .35)",
            },
        },
        "&:has(.MuiOutlinedInput-input:-webkit-autofill)": {
            backgroundColor: "transparent !important",
            "& .MuiOutlinedInput-input:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 100px rgb(45, 49, 55) inset !important",
            },
        },
    },
    "& input": {
        backgroundColor: "transparent !important",
        fontSize: "14px",
        padding: 0,
        maxHeight: "100%",
    },
    "& fieldset": {
        backgroundColor: "transparent",
    },
}));
