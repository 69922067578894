import { EndpointEnum } from "enums/endpoint.enum";
import { get } from "./http.client";

interface CurrencyResponse {
    success: boolean;
    timestamp: number;
    base: string;
    date: string;
    rates: Record<string, number> | null;
}

export const fetchLocaleCurrency = async (countryCode?: string) => {
    return get<CurrencyResponse>(`${EndpointEnum.EXCHANGE_RATE}`, { countryCode });
};
