export const formStyles = {
    maxWidth: { xs: "358px", lmd: "400px" },
    "& .plan-name": {
        fontSize: "34px",
    },
    "& .price": {
        mb: "42px",
        "& span": {
            fontSize: "23px",
        },
    },
    "& .applepay-btn": {
        mt: "8px",
        background: "transparent",
    },
    "& .form-inputs-wrap": {
        padding: 0,
        "&>.MuiStack-root>.MuiStack-root:nth-of-type(2)": {
            my: "24px",
        },
        "&>.MuiStack-root>.MuiStack-root:last-of-type": {
            mt: 0,
        },
    },
    "& .disclaimer": {
        color: "text.disabled",
    },
};
