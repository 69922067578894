export interface PlanCardProps {
    cardVariant?: "dark" | "light";
    title: string;
    sideStatus: string | number;
    trialPriceVat: number;
    oldPrice: number;
    duration: string;
    priceSubtitle: string;
    buttonText: string;
    checklist: string[];
}

export const planVariants: PlanCardProps[] = [
    {
        cardVariant: "light",
        title: "Advanced",
        sideStatus: "Most popular",
        trialPriceVat: 4.99,
        oldPrice: 9.99,
        duration: "24h Trial",
        priceSubtitle: "Limited offer",
        buttonText: "get started",
        checklist: [
            "24 Trackings Per Day",
            "Real-time Location",
            "benefit_title_2",
            "Search History",
            "Security and ease of use",
            "Compatible with iOS, Android & Web",
        ],
    },
    {
        cardVariant: "dark",
        title: "Pro",
        sideStatus: 0.49,
        trialPriceVat: 14.99,
        oldPrice: 49.99,
        duration: "mo",
        priceSubtitle: "Save 70%",
        buttonText: "Select Plan",
        checklist: [
            "Unlimited Daily Trackings",
            "Real-time Location",
            "benefit_title_2",
            "Search History",
            "Security and ease of use",
            "Compatible with iOS, Android & Web",
        ],
    },
];
