import { Divider, DividerProps, Typography } from "@mui/material";
import { FC } from "react";

interface Props extends DividerProps {
    title: string;
}

export const PaymentMethodsDivider: FC<Props> = ({ title, sx, ...props }) => {
    return (
        <Divider
            className="payment-methods-divider"
            sx={{
                "&:before, &:after": { borderTop: "1px solid #383838", mt: "3px" },
                color: "text.disabled",
                mb: "16px",
                ...sx,
            }}
            {...props}
        >
            <Typography variant="dmSansBody2Regular" fontSize="12px" lineHeight={1}>
                {title}
            </Typography>
        </Divider>
    );
};
