import z from "zod";

export const ixopayFormSchema = z.object({
    expiry: z
        .string({
            required_error: "Expiry date is required",
            invalid_type_error: "Enter valid Expiry date",
        })
        .optional(),
    card_holder: z.string({
        required_error: "Cardholder name is required",
        invalid_type_error: "Enter valid cardholder name",
    }),
});
