import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { IXOPAY_INTEGRATION_KEY } from "constants/env.const";
import { AppRoutes } from "enums/routes.enum";
import { checkPaymentOneClickInfo, makeSubscriptionTransaction, MakeSubscriptionTransactionParams } from "http-client/payment.client";
import { ampli, SubscriptionSubscribeClickedProperties } from "services/ampli";
import { loadFromSessionStorage, saveToSessionStorage } from "services/storage";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { PaywallType, setFieldsStyles } from "../utils";
import { IxopayFormData } from "../types";
import { useHandleTransactionResponse } from "./useHandleTransactionResponse";
import { getPaymentRedirectUrl } from "constants/getPaymentRedirectUrl.const";

interface Props {
    formSpyRef: any;
    paymentJsRef: any;
    hasFormData: boolean;
    data: IxopayFormData | null;
    userEmail: string;
    paywall: PaywallType;
    submitEventProps?: Omit<SubscriptionSubscribeClickedProperties, "success" | "reason">;
}

export const useIxopayFormData = ({ formSpyRef, paymentJsRef, hasFormData, data, userEmail, paywall, submitEventProps }: Props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { amplitudeUserId, abTestData } = useAppData();
    const { userWebSession } = useUserStore();
    const handleTransactionResponse = useHandleTransactionResponse();
    const { plan, successQuery, declinePage, declineQuery } = data || {};
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [oneClickCardData, setOneClickCardData] = useState<Record<string, any> | null>(null);
    const abTestPaywall = abTestData?.abTestPaywall || userWebSession?.session?.abTestData?.abTestPaywall;
    const userData = userWebSession?.session?.user;

    const numberOn = {
        blur: () => {
            formSpyRef.current?.clearErrors("number");
        },
        input: () => {
            formSpyRef.current?.clearErrors("number");
        },
    };
    const cvvOn = {
        blur: () => {
            formSpyRef.current?.clearErrors("cvv");
        },
        input: () => {
            formSpyRef.current?.clearErrors("cvv");
        },
    };

    useEffect(() => {
        if (hasFormData && oneClickCardData && !oneClickCardData?.token) {
            paymentJsRef.current = new window.PaymentJs();
            paymentJsRef.current?.init(IXOPAY_INTEGRATION_KEY, "number", "cvv", (payment: any) => {
                setFieldsStyles({ payment, numberOn, cvvOn, paywall });
                payment.setNumberPlaceholder(t("Card number"));
                payment.setCvvPlaceholder(t("CVV/CVC"));
                setSpinnerLoading(false);
            });
        }

        const handle3DSPostMessage = async (event: any) => {
            const { type, payload } = event.data || {};

            if (type === "3DS-complete") {
                const isSuccess = payload?.success === "true";
                const provider = payload?.provider;

                if (isSuccess) {
                    const paymentOneClickInfo = await checkPaymentOneClickInfo(userEmail);
                    const { uuid, paymentData } = paymentOneClickInfo || {};
                    const { firstSixDigits, lastFourDigits } = paymentData || {};

                    if (uuid) {
                        saveToSessionStorage("one-click-card", JSON.stringify({ token: uuid, card: `${firstSixDigits}******${lastFourDigits}` }));
                    }
                }

                const page = isSuccess ? AppRoutes.SUCCESS : declinePage;
                const query = isSuccess ? successQuery : declineQuery;
                query.provider = provider;

                // If you need to remove an iframe, you can do it here
                // document.querySelector(".ixopay-3ds-flow iframe")?.remove();
                navigate({ pathname: page, search: createSearchParams(query).toString() });
            }
        };

        window.addEventListener("message", handle3DSPostMessage, false);
        return () => {
            window.removeEventListener("message", handle3DSPostMessage);
        };
    }, [hasFormData, oneClickCardData, i18n.language]);

    const generateTransactionParams = (token: string, cardData) => {
        const [firstName, lastName = ""] = cardData.full_name.trim().replace(/\s+/g, " ").split(" ");

        const redirect = getPaymentRedirectUrl("ixopay");
        const successUrl = redirect.success;
        const errorUrl = redirect.error;

        const params: Partial<MakeSubscriptionTransactionParams> = {
            userAmpliId: amplitudeUserId,
            ccToken: token,
            subscriptionPlanId: plan?.id as string,
            applicationContext: {
                successUrl,
                cancelUrl: errorUrl,
                errorUrl,
            },
            customer: {
                firstName,
                lastName,
            },
        };

        return { params };
    };

    const isValidCardHolderByCountry = (value: string) => {
        if (!["US", "CA", "AU", "NZ"].includes(userData?.countryCode ?? "US")) {
            return true;
        }

        return value.trim().split(/\s+/).length >= 2;
    };

    const handleSubmit = async (methods: UseFormReturn, values: any): Promise<void> => {
        if (!paymentJsRef.current || !data) return;
        setLoading(true);

        const props = submitEventProps ?? {
            type: abTestPaywall,
            product_id: plan?.planId || "",
            plan: plan?.name || "",
        };

        if (!isValidCardHolderByCountry(values.card_holder)) {
            const message = "String must contain two or more words";
            handleAmpliLoaded(() => ampli.subscriptionSubscribeClicked({ ...props, success: false, reason: message }));
            setLoading(false);
            return methods.setError("card_holder", {
                type: "server",
                message,
            });
        }

        try {
            const [month, year] = values.expiry.split("/");
            const formData = {
                card_holder: values.card_holder,
                month,
                year: `20${year}`,
            };

            paymentJsRef.current.tokenize(
                formData,
                async (token: string, cardData) => {
                    setSpinnerLoading(true);
                    const { params } = generateTransactionParams(token, cardData);
                    const transactionResponse = await makeSubscriptionTransaction(params as MakeSubscriptionTransactionParams);
                    await handleTransactionResponse({ transactionResponse, data, provider: "ixopay" }).catch();
                    handleAmpliLoaded(() => ampli.subscriptionSubscribeClicked({ ...props, success: true }));
                    setLoading(false);
                },
                (errors: any) => {
                    for (const error of errors) {
                        let hasSetExpiryError = false;

                        if (error.attribute === "month") {
                            hasSetExpiryError = true;
                            methods.setError("expiry", {
                                type: "server",
                                message: error.message,
                            });
                        }

                        if (error.attribute === "year" && !hasSetExpiryError) {
                            methods.setError("expiry", {
                                type: "server",
                                message: error.message,
                            });
                        }

                        methods.setError(error.attribute, {
                            type: "server",
                            message: error.message,
                        });
                    }

                    const mergedErrors = errors.map((error) => error.message).join(" / ");

                    handleAmpliLoaded(() => ampli.subscriptionSubscribeClicked({ ...props, success: false, reason: mergedErrors }));
                    setLoading(false);
                }
            );
        } catch (err: any) {
            console.error("Form data has not been submitted");
        }
    };

    useEffect(() => {
        setOneClickCardData(loadFromSessionStorage("one-click-card") || {});
    }, []);

    return {
        handleSubmit,
        numberOn,
        cvvOn,
        spinnerLoading,
        loading,
        setSpinnerLoading,
    };
};
