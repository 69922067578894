import { useCallback, useMemo, useState } from "react";

interface CurrencyFormatOptions {
    countryCode?: string;
}

const defaultCurrencyRate = {
    code: "USD",
    rate: 1,
};

export const useCurrencyFormat = ({ countryCode = "US" }: CurrencyFormatOptions) => {
    const [currencyOptions, setCurrencyOptions] = useState(defaultCurrencyRate);

    const localeCountryCode = useMemo((): string => {
        const locale = new Intl.Locale("und", { region: countryCode });
        const minimizedLocale = locale.minimize();
        return minimizedLocale.baseName;
    }, [countryCode]);

    const formatLocaleCurrency = useCallback(
        (amount: number) => {
            const value = amount * currencyOptions.rate;

            if (isNaN(value)) {
                return undefined;
            }

            return new Intl.NumberFormat(localeCountryCode, {
                style: "currency",
                currency: currencyOptions.code,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value);
        },
        [...Object.values(currencyOptions), localeCountryCode]
    );

    return {
        setCurrencyOptions,
        formatLocaleCurrency,
    };
};
