export enum LocationStatus {
    PENDING = "PENDING",
    SENT = "SENT",
    SENDING_ERROR = "SENDING_ERROR",
    API_LOCATED = "API_LOCATED",
    GPS_LOCATED = "GPS_LOCATED",
}

export enum LocationTarget {
    GPS = "GPS",
    API = "API",
}

export enum LocationType {
    FIND_DEVICE = "find-device",
    LOST_PHONE = "lost-phone",
    LINK = "link",
}

interface DeviceInfo {
    ip: string;
    city: string;
    country: string;
    osName: string;
    language: string;
    osVersion: string;
    deviceType: string;
    screenSize: string;
    browserName: string;
    browserVersion: string;
}

export interface LostPhoneInfo {
    email: string;
    name?: string;
    alternativePhone: string;
    reward: string;
}

interface LocationDetails {
    searchNumber?: string;
    deviceInfo?: DeviceInfo;
}

interface LocationDetailsWithLostPhone extends LocationDetails {
    lostPhoneInfo: LostPhoneInfo;
}

interface BaseILocation {
    id: string;
    name: string;
    userId: string;
    latitude?: string | null;
    longitude?: string | null;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    status: LocationStatus;
    target: LocationTarget | null;
    type: LocationType;
}

export type ILocationForFindDevice = BaseILocation & {
    type: LocationType.FIND_DEVICE;
    details: LocationDetails | null;
};

export type ILocationForLostPhone = BaseILocation & {
    type: LocationType.LOST_PHONE;
    details: LocationDetailsWithLostPhone;
};

export type ILocationForLink = BaseILocation & {
    type: LocationType.LINK;
    details: LocationDetails | null;
};

export type ILocation = ILocationForFindDevice | ILocationForLostPhone | ILocationForLink;
