import { useDeviceDetect } from "hooks/useDeviceDetect";
import { useAppData } from "providers/RootStoreProvider";
import { getUTCTimezoneOffsetInHours } from "../utils/getUTCTimezoneOffsetInHours";

export const use3DSBrowserInfo = () => {
    const { isMobile } = useDeviceDetect();
    const { ipInfo } = useAppData();

    const get3DSecureBrowserInfo = () => {
        const browserTimezone = getUTCTimezoneOffsetInHours() ?? 0;
        const javaEnabled = navigator.javaEnabled();

        const threeDSecureInfo = {
            browserChallengeWindowSize: isMobile ? "02" : "03",
            browserIpAddress: ipInfo?.ip,
            browserJavaEnabled: !!javaEnabled,
            browserLanguage: navigator.language,
            browserColorDepth: screen.colorDepth.toString(),
            browserScreenHeight: screen.height,
            browserScreenWidth: screen.width,
            browserTimezone,
            browserUserAgent: navigator.userAgent,
            browserPlatform: navigator.platform || "",
        };

        return threeDSecureInfo;
    };

    return get3DSecureBrowserInfo;
};
