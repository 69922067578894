import { configWebAttribution } from "@kissmyapps-tlm/client-web-attribution";
import { ampli } from "./ampli";
import { isProductionHost } from "helpers/isProductionHost";
import { PROJECT_NAME } from "constants/env.const";

export const { webAttribution, loadAmpli } = configWebAttribution({
    ampli,
    environment: isProductionHost() ? "production" : "development",
    projectName: PROJECT_NAME,
    disableSessionReplayTracking: true,
});
