import { PaywallVariants } from "enums/PaywallVariants";
import { useAppData } from "providers/RootStoreProvider";
import { FC, ReactNode } from "react";
import { paywallWrapperVariants } from "./variants";

interface Props {
    paywallVariant: PaywallVariants;
    children: ReactNode;
}

export const PaywallWrapper: FC<Props> = ({ paywallVariant, children }) => {
    const { flowOuter } = useAppData();

    const getWrapperVariant = () => {
        if (!flowOuter) return paywallWrapperVariants.default;
        return paywallWrapperVariants?.[paywallVariant] ?? paywallWrapperVariants.default;
    };

    const PaywallWrapperVariant = getWrapperVariant();

    return <PaywallWrapperVariant>{children}</PaywallWrapperVariant>;
};
