import { Box, Stack, Typography } from "@mui/material";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { useFakeSearch } from "hooks/useFakeSearch";
import { useAppData } from "providers/RootStoreProvider";
import { ReactComponent as BackArrowIcon } from "assets/icons/back-arrow.svg";
import { formatNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import FakeSearchMap from "components/Map/FakeSearchMap";
import { paywallMapTheme } from "constants/paywallMapTheme.const";
import { useBoolean } from "hooks/useBoolean";
import { useEffect, useState } from "react";

interface Props {
    discountRate: number;
    withStepBack?: boolean;
}

const placeholderLocation = {
    lat: 50.397061,
    lng: -19.746916,
};

export const FakePaywallMap = ({ withStepBack, discountRate }: Props) => {
    const { t } = useTranslation();
    const { searchPhoneNumber, isPaymentMapFormShown, setIsPaymentMapFormShown, ipInfo } = useAppData();
    const [userZoom, setUserZoom] = useState(1);
    const { searchLocation, mapPaywallSearchStatus } = useFakeSearch({
        withMap: true,
        disableRedirect: true,
        disableClickEventSend: true,
    });
    const [userLocation, setUserLocation] = useState<Record<string, any>>(searchLocation ?? placeholderLocation);
    const { isMobile } = useDeviceDetect();

    const [isMapLoaded, setIsMapLoaded] = useBoolean(false);

    const searchStatus = mapPaywallSearchStatus;
    const showArrow = isMobile && isPaymentMapFormShown && withStepBack;
    const showDiscountBadge = (isMobile && isPaymentMapFormShown) || (isMobile && !withStepBack);

    useEffect(() => {
        if (searchLocation) return;

        (async () => {
            if (ipInfo) {
                const location = ipInfo?.loc.split(",");
                setUserLocation({
                    lat: +location[0],
                    lng: +location[1],
                });
            }
        })();
    }, [ipInfo]);

    useEffect(() => {
        if (!userLocation || searchLocation) return;

        const interval = setInterval(() => {
            if (userZoom < 10) {
                setUserZoom((zoom) => zoom + 3);
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [userLocation, userZoom]);

    const handleMapLoaded = () => {
        setTimeout(() => {
            setIsMapLoaded.on();
        }, 400);
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: { xs: "218px", sm: "286px" },
                position: "relative",
                overflow: "hidden",
                zIndex: 4,
                borderRadius: {
                    xs: "22px 22px 0 0",
                    sm: "22px 0 22px 0",
                },
                mb: { xs: ".6rem", sm: "2rem" },
                WebkitTransform: "translateZ(0)",
                WebkitMaskImage: "-webkit-radial-gradient(circle, white 100%, black 100%)",
            }}
        >
            <Box
                sx={(theme) => ({
                    width: "100%",
                    height: "100%",
                    borderRadius: {
                        xs: "22px 22px 0 0",
                        sm: "22px 0 22px 0",
                    },
                    border: { xs: "none", sm: `1px solid ${theme.palette.background.brightGreen}` },
                })}
            >
                {showArrow && (
                    <BackArrowIcon
                        onClick={() => setIsPaymentMapFormShown(false)}
                        style={{
                            position: "absolute",
                            top: ".5rem",
                            left: ".5rem",
                        }}
                    />
                )}
                {showDiscountBadge && (
                    <Typography
                        variant="body1CaptionBold"
                        sx={(theme) => ({
                            color: theme.palette.text.secondary,
                            textTransform: "uppercase",
                            textAlign: "center",
                            position: "absolute",
                            padding: "0 10px 10px",
                            borderRadius: "0 0 1rem 1rem",
                            background: theme.palette.background.brightGreen,
                            top: 0,
                            left: "50%",
                            transform: "translateX(-50%)",
                            whiteSpace: "nowrap",
                        })}
                    >
                        {t("SPECIAL OFFER")} - {discountRate}%
                    </Typography>
                )}
                {searchLocation && (
                    <Stack
                        direction="row"
                        gap="12px"
                        sx={{
                            padding: "13px 16px",
                            borderRadius: "16px",
                            border: `2px solid ${searchStatus.color}`,
                            background: searchStatus.bgColor,
                            backdropFilter: "blur(5px)",
                            margin: { xs: "20% auto 0", sm: "30% auto 0" },
                            width: "fit-content",
                        }}
                    >
                        <img
                            src={searchStatus.icon}
                            alt={searchStatus.statusTitle}
                            style={{
                                width: "44px",
                                height: "auto",
                                alignSelf: "flex-start",
                            }}
                        />
                        <Box>
                            <Typography
                                dir="ltr"
                                variant="title1Bold"
                                sx={(theme) => ({ color: theme.palette.text.secondary })}
                                whiteSpace="nowrap"
                                fontSize="24px"
                            >
                                {formatNumber(searchPhoneNumber, "INTERNATIONAL")}
                            </Typography>
                            <Typography variant="smallRegular" sx={{ color: searchStatus.color, fontSize: "12px", display: "block" }}>
                                {t(searchStatus.statusTitle)}!
                            </Typography>
                        </Box>
                    </Stack>
                )}
                <FakeSearchMap
                    lat={userLocation.lat}
                    lng={userLocation.lng}
                    theme={paywallMapTheme}
                    zoom={searchLocation ? 12 : userLocation ? userZoom : 1}
                    onLoad={handleMapLoaded}
                    mapContainerStyle={{
                        width: "115%",
                        height: "115%",
                        position: "absolute",
                        top: "-7%",
                        left: "-7%",
                        borderRadius: "22px 0 22px 0",
                        zIndex: -1,
                        opacity: isMapLoaded ? 1 : 0,
                        transition: ".6s",
                    }}
                />
            </Box>
        </Box>
    );
};
