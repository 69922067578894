import { Box, styled } from "@mui/material";

const StyledCard = styled(Box)(({ theme }) => ({
    borderRadius: "24px",
    padding: "16px 24px 94px",
    minWidth: "370px",
    width: "100%",
    "& .side-status": {
        fontWeight: 600,
        fontFamily: theme.typography.dmSansBody1Regular.fontFamily,
    },
    [theme.breakpoints.down("lmd")]: {
        minWidth: "unset",
    },
}));

export const StyledCardLight = styled(StyledCard)(({ theme }) => ({
    backgroundColor: theme.palette.background.quaternary,
    boxShadow: "0px 0px 8px 0px rgba(255, 242, 242, 0.50)",
    "& .side-status": {
        padding: "0 9px",
        borderRadius: "0px 16px",
        background: theme.palette.text.yellow,
        color: theme.palette.text.primary,
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
        height: "28px",
    },
    "& .MuiTypography-root": {
        color: theme.palette.text.primary,
    },
    "& .MuiButtonBase-root": {
        backgroundColor: theme.palette.background.brightGreen,
    },
    "& .price-subtitle": {
        color: theme.palette.text.tertiary,
    },
    "& svg": {
        "& circle": {
            fill: theme.palette.text.primary,
        },
        "& path": {
            stroke: theme.palette.background.lightAccent,
        },
    },
    [theme.breakpoints.down("lmd")]: {
        "& .price-subtitle": {
            fontWeight: 400,
        },
    },
}));

export const StyledCardDark = styled(StyledCard)(({ theme }) => ({
    background: "transparent",
    border: `1px solid ${theme.palette.text.quaternary}`,
    "& .side-status": {
        color: `${theme.palette.text.yellow} !important`,
    },
    "& .MuiTypography-root": {
        color: theme.palette.text.secondary,
    },
    "& .MuiButtonBase-root": {
        background: "rgba(114, 120, 154, 0.10)",
        border: "1px solid rgba(114, 120, 154, 0.5)",
        "&:hover": {
            backgroundColor: theme.palette.background.buttonHoverBg,
        },
    },
    "& .price-subtitle": {
        color: theme.palette.text.yellow,
    },
    "& svg": {
        "& circle": {
            fill: theme.palette.background.lightAccent,
        },
        "& path": {
            stroke: theme.palette.text.primary,
        },
    },
}));
