import { useEffect } from "react";

export const useDisableScreen = (loading: boolean) => {
    useEffect(() => {
        document.body.style.pointerEvents = loading ? "none" : "unset";

        return () => {
            document.body.style.pointerEvents = "unset";
        };
    }, [loading]);
};
