import { Stack } from "@mui/material";
import { Image, SectionGradientDarkener } from "components/shared";
import { FC, ReactNode } from "react";

interface Props {
    children?: ReactNode;
}

export const PaywallWrapper: FC<Props> = ({ children }) => {
    return (
        <Stack sx={{ width: "100%", position: "relative", backgroundColor: "text.primary", minHeight: "105vh", pb: "11vh", overflow: "hidden" }}>
            <Image
                png={{ url: "/images/search-page-bg.png" }}
                webp={{ url: "/images/search-page-bg.webp" }}
                style={{ position: "absolute", width: "auto", height: "170vh", objectFit: "cover" }}
            />
            <SectionGradientDarkener />
            {children}
        </Stack>
    );
};
