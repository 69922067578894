import { useCurrencyFormat } from "../hooks/useCurrencyFormat";

export type IxopayFormData = Record<string, any>;

export interface IxopayFormProps {
    formDisabled?: boolean;
    data: IxopayFormData | null;
    paypalPlan?: Record<string, any> | null;
    formatLocaleCurrency: ReturnType<typeof useCurrencyFormat>["formatLocaleCurrency"];
}

export enum PaymentButtonTitleVariants {
    CONTINUE = "Continue",
    START_TRIAL = "Start trial",
    PAY_NOW = "pay now",
}
