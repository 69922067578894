import { Outlet, RouterProvider, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/styles";
import { AmplitudeProvider } from "providers/AmplitudeProvider";
import { RootStoreProvider } from "providers/RootStoreProvider";
import { DirectionWrapper } from "providers/wrappers";
import { AuthCheck } from "components/AuthCheck";
import { ScrollToTop } from "components/ScrollToTop";
import { ScrollToHash } from "components/ScrollToHash";
import { routes } from "constants/routes.const";
import { setLanguage } from "helpers/language.helpers";
import { GlobalStyle } from "GlobalStyle";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { ThemeProvider } from "@mui/material";
import { theme } from "mui-theme/mui-theme";
import { AppRoutes } from "enums/routes.enum";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID, NODE_ENV } from "constants/env.const";
import { FOOTER_EXCLUDED_PATHS } from "constants/footerExcludedPaths.const";
import { HEADER_EXCLUDED_PATHS } from "constants/headerExcludedPaths.const";
import { sentryCreateBrowserRouter } from "services/sentry/sentry.router";
import { DevToolsWidget } from "components/DevToolsWidget";

const isProduction = NODE_ENV === "production";

const RootLayout = () => {
    setLanguage();
    const { pathname } = useLocation();
    const muiTheme = theme;

    const showHeader =
        !HEADER_EXCLUDED_PATHS.includes(pathname as AppRoutes) && !pathname.includes(AppRoutes.SHARE_NON_LEGAL) && !pathname.includes(AppRoutes.SHARE_LEGAL);
    const showFooter =
        !FOOTER_EXCLUDED_PATHS.includes(pathname as AppRoutes) && !pathname.includes(AppRoutes.SHARE_NON_LEGAL) && !pathname.includes(AppRoutes.SHARE_LEGAL);

    return (
        <RootStoreProvider>
            <ThemeProvider theme={muiTheme}>
                <CssVarsProvider theme={muiTheme}>
                    <PayPalScriptProvider
                        deferLoading
                        options={{
                            clientId: PAYPAL_CLIENT_ID,
                            components: "buttons",
                            intent: "subscription",
                            vault: true,
                        }}
                    >
                        <CssBaseline />
                        <AuthCheck>
                            <AmplitudeProvider>
                                <ScrollToTop />
                                <ScrollToHash />
                                <GlobalStyle />
                                {showHeader && <Header />}
                                <DirectionWrapper>
                                    <Outlet />
                                </DirectionWrapper>
                                {showFooter && <Footer />}
                                {!isProduction && <DevToolsWidget />}
                            </AmplitudeProvider>
                        </AuthCheck>
                    </PayPalScriptProvider>
                </CssVarsProvider>
            </ThemeProvider>
        </RootStoreProvider>
    );
};

const router = sentryCreateBrowserRouter([
    {
        element: <RootLayout />,
        children: [...routes],
    },
]);

export function App() {
    return <RouterProvider router={router} />;
}
