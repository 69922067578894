import { useAppData } from "providers/RootStoreProvider";
import { useFbConfigData } from "providers/hooks";
import { useCallback, useMemo } from "react";

export enum LegalAddressVariant {
    LEGAL_US = "legal_us",
    LEGAL_NON_US = "legal_non_us",
    NON_LEGAL_US = "non_legal_us",
    NON_LEGAL_NON_US = "non_legal_non_us",
}

const defaultCompanyData = {
    companyName: "",
    registryCode: "",
    legislationState: "",
    address: "",
};

export const useLegalAddress = () => {
    const { remoteConfigData } = useFbConfigData();
    const { ipInfo, flowOuter } = useAppData();
    const isUSIp = useMemo(() => ipInfo?.country === "US", [ipInfo]);
    const abAddressVariants = remoteConfigData?.legalAddressConfig ?? {};

    const getAddressVariant = useCallback(() => {
        if (!remoteConfigData || !ipInfo) return;

        let addressVariant;
        if (flowOuter) {
            addressVariant = isUSIp ? LegalAddressVariant.NON_LEGAL_US : LegalAddressVariant.NON_LEGAL_NON_US;
        } else {
            addressVariant = isUSIp ? LegalAddressVariant.LEGAL_US : LegalAddressVariant.LEGAL_NON_US;
        }

        return abAddressVariants[addressVariant] ?? defaultCompanyData;
    }, [remoteConfigData, flowOuter, ipInfo]);

    const companyData = getAddressVariant();

    return { companyData, isUSIp };
};
