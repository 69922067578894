export enum AppRoutes {
    HOME = "/",
    HOME_LOST_PHONE = "/lost-phone",
    SEARCH = "/search",
    SIGN_UP = "/sign-up",
    LOG_IN = "/login",
    OAUTH = "/oauth",
    PRIVACY_POLICY = "/privacy-policy",
    TERMS_OF_USE = "/terms-of-use",
    COOKIE_POLICY = "/cookie-policy",
    PAYMENT = "/payment",
    FORGOT_PASSWORD = "/forgot-password",
    ACCOUNT = "/account",
    FIND_DEVICE = "/account/find-device",
    LOST_PHONE = "/account/lost-phone",
    GENERATE_LINK = "/account/generate-link",
    NEW_PASSWORD = "/new-password",
    SUCCESS = "/success",
    DECLINE = "/decline",
    SECURE_FLOW_RESULT = "/secure-flow-result",
    SHARE_LEGAL = "/share",
    SHARE_NON_LEGAL = "/share-location",
    RESTRICTED_ACCESS = "/restricted",
}
