import { FC, useEffect, useState } from "react";
import { IxopayFormProps, PaymentButtonTitleVariants } from "components/Payment/shared/types";
import { useBoolean } from "hooks/useBoolean";
import { useTranslation } from "hooks/useTranslation";
import { observer } from "mobx-react-lite";
import { ampli } from "services/ampli";
import { saveToSessionStorage } from "services/storage";
import { PlanVariantsList } from "./components";
import { Box, Stack, Typography } from "@mui/material";
import { BorderedGreyTextField, FormWithDiscountAndDisclaimer } from "components/Payment/shared/components";
import { PaywallType } from "components/Payment/shared/utils";
import { ReactComponent as SecureBadgeIcon } from "assets/icons/secure-badge-rounded-filled.svg";
import { formStyles } from "./constants";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { ReactComponent as BackArrowIcon } from "assets/icons/back-arrow-white.svg";

const defaultPlanExtraData = {
    title: "Advanced Plan",
    oldPrice: 9.99,
};

interface Props extends IxopayFormProps {
    plans: any[] | undefined;
}

const PaymentForm: FC<Props> = observer(({ data, plans, formatLocaleCurrency }) => {
    const {
        t,
        detect: { isSpanishLanguage, isPortugueseLanguage },
    } = useTranslation();
    const [isPaymentFormVisible, setPaymentFormVisibility] = useBoolean();
    const defaultPlan = plans?.find((plan) => plan.trialPriceVat === 4.99 && plan.priceVat === 49.99);
    const [chosenPlan, setChosenPlan] = useState<Record<string, any> | undefined>(defaultPlan);
    const [planExtraData, setPlanExtraData] = useState(defaultPlanExtraData);

    const isLargeContentMobileTitle = isSpanishLanguage || isPortugueseLanguage;

    const setPlanAndStoragePlan = (plan) => {
        setChosenPlan(plan);
        saveToSessionStorage("selected-plan", JSON.stringify(plan));
    };

    useEffect(() => {
        if (!defaultPlan) return;
        setPlanAndStoragePlan(defaultPlan);
    }, [defaultPlan]);

    const handlePlanChoose = ({ trialPriceVat, oldPrice, title }) => {
        const currentPlan = plans?.find((plan) => plan.trialPriceVat === trialPriceVat && plan.priceVat === 49.99);
        handleAmpliLoaded(() =>
            ampli.subscriptionContinueClicked({
                type: "two_plans_v1",
                plan: currentPlan?.name || "",
            })
        );
        setPlanAndStoragePlan(currentPlan);
        setPlanExtraData({ oldPrice, title: `${title} Plan` });
        setPaymentFormVisibility.on();
        setTimeout(() => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }, 100);
    };

    return (
        <Stack sx={{ pt: "120px", alignItems: "center", width: { xs: "calc(100% + 8px)", lmd: "100%" }, ml: { xs: "-4px", lmd: 0 } }}>
            {isPaymentFormVisible ? (
                <Box>
                    <BackArrowIcon style={{ position: "absolute", top: "88px", left: 0, cursor: "pointer" }} onClick={setPaymentFormVisibility.off} />
                    <FormWithDiscountAndDisclaimer
                        formTitle={
                            <Typography variant="dmSansBody2Semibold" sx={{ color: "text.disabled", mb: "8px", display: "block" }} className="form-title">
                                {t("Pay with")}
                            </Typography>
                        }
                        submitButtonProps={{
                            children: t(PaymentButtonTitleVariants.PAY_NOW),
                            sx: {
                                backgroundColor: "rgba(95, 203, 57, 0.6)",
                                mb: "24px",
                            },
                        }}
                        sx={{
                            "& .form-fields-wrap:before": {
                                content: `"${t("card details")}"`,
                                fontFamily: "dmSansH5Regular.fontFamily",
                                fontWeight: 600,
                                fontSize: "14px",
                                color: "text.disabled",
                                mb: "8px",
                                textTransform: "capitalize",
                            },
                            "& #gpay-button-online-api-id": { outline: "1px solid #000 !important" },
                            ...formStyles,
                        }}
                        data={{ ...data, plan: chosenPlan }}
                        paywallType={PaywallType.twoSteps}
                        textFieldComponent={BorderedGreyTextField}
                        planExtraData={planExtraData}
                        formatLocaleCurrency={formatLocaleCurrency}
                        secureNotification={
                            <Stack direction="row" alignItems="center" gap="8px" sx={{ mb: "32px", mt: "-12px" }}>
                                <SecureBadgeIcon />
                                <Typography variant="dmSansBody2Regular" sx={{ fontSize: "12px", color: "text.secondary" }}>
                                    {t("This is a secure 256-bit SSL encrypted payment")}
                                </Typography>
                            </Stack>
                        }
                    />
                </Box>
            ) : (
                <>
                    <Typography
                        variant="dmSansH3Semibold"
                        sx={{
                            fontSize: { xs: "40px", lmd: "64px" },
                            lineHeight: 1.2,
                            mb: "32px",
                            color: "brandColors.brandWhite",
                            textAlign: "center",
                            maxWidth: { xs: isLargeContentMobileTitle ? "100%" : "90%", lmd: "100%" },
                        }}
                    >
                        {t("Locate Any Phone Instantly")}
                    </Typography>
                    <Typography
                        variant="dmSansH5Regular"
                        sx={{
                            mb: "24px",
                            color: "brandColors.brandWhite",
                            maxWidth: { xs: "100%", lmd: "60%" },
                            textAlign: "center",
                            fontSize: { xs: "16px", lmd: "23px" },
                            lineHeight: 1.25,
                        }}
                    >
                        {t("We are proud to say that we have received the trust of over 5+ million people")}
                    </Typography>
                    <Box component="img" src="images/avatars-horizontal-grid.png" sx={{ maxWidth: { xs: "336px", lmd: "408px" }, mb: "74px" }} />
                    <Stack
                        sx={{
                            flexDirection: { xs: "column", lmd: "row" },
                            gap: { xs: "48px", lmd: "24px" },
                            justifyContent: "center",
                            width: { xs: "100%", lmd: "unset" },
                        }}
                    >
                        <PlanVariantsList onClick={handlePlanChoose} formatLocaleCurrency={formatLocaleCurrency} />
                    </Stack>
                </>
            )}
        </Stack>
    );
});

export default PaymentForm;
