import { createSearchParams, useNavigate } from "react-router-dom";
import { IxopayFormData } from "../types";

interface Props {
    data: IxopayFormData | null;
    transactionResponse: any;
    provider: "ixopay" | "googlepay";
}

export const useHandleTransactionResponse = () => {
    const navigate = useNavigate();

    const handleTransactionResponse = async ({ data, transactionResponse, provider }: Props) => {
        if (!data) return;

        const { successPage, successQuery, declineQuery, declinePage } = data;

        if (transactionResponse?.success && transactionResponse?.returnType === "REDIRECT") {
            const iframe = document?.createElement("iframe");
            iframe.src = transactionResponse.redirectUrl;
            (iframe as any).style =
                "position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; background-color: #fff; z-index:999999;";
            document?.querySelector(".ixopay-3ds-flow")?.appendChild(iframe);
        }

        if (data?.successPage && transactionResponse?.success && transactionResponse?.returnType === "FINISHED") {
            setTimeout(() => {
                navigate({ pathname: successPage, search: createSearchParams({ ...successQuery, provider }).toString() });
            }, 800);
        }

        if (data?.declinePage && !transactionResponse?.success) {
            setTimeout(() => {
                navigate({ pathname: declinePage, search: createSearchParams({ ...declineQuery, provider }).toString() });
            }, 800);
        }
    };

    return handleTransactionResponse;
};
