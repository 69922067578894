export enum LandingType {
    LOST_PHONE = "lost_phone",
    LOCATE_NUMBER = "locate_number",
    DEFAULT = "default",
}

export enum LandingVariant {
    FAQ_V2 = "faq_2.0",
    FAQ_V3 = "faq_3.0",
}

export enum LandingMode {
    LOCATE_NUMBER_MODE = "locate by number",
    LOST_MY_PHONE_MODE = "lost my phone",
    LOCATE_BY_LINK = "locate by link",
}
