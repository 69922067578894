import { Navigate } from "react-router-dom";
import { AppRoutes } from "enums/routes.enum";
import FakeSearch, { fakeSearchLoader } from "pages/FakeSearch";
import SignUp, { signUpLoader } from "pages/SignUp";
import Payment, { PaymentLoader } from "pages/Payment";
import LogIn, { logInLoader } from "pages/LogIn";
import OauthPage, { oauthLoader } from "../pages/Oauth";
import { AccountLayout, FindDevice, findDeviceLoader, GenerateLink, generateLinkLoader, LostPhone, lostPhoneLoader } from "pages/Account";
import ForgotPassword, { forgotPasswordLoader } from "pages/ForgotPassword";
import NewPassword, { newPasswordLoader } from "pages/NewPassword";
import SuccessPayment, { successPaymentLoader } from "pages/SuccessPayment";
import DeclinePayment, { declinePaymentLoader } from "pages/DeclinePayment";
import { PaymentResult } from "pages/PaymentResult";
import CookiePolicy, { cookiePolicyLoader } from "pages/CookiePolicy";
import TermsOfUse, { termsOfUseLoader } from "pages/TermsOfUse";
import PrivacyPolicy, { privacyPolicyLoader } from "pages/PrivacyPolicy";
import RestrictedAccessByIp, { restrictedAccessByIpLoader } from "pages/RestrictedAccessByIp";
import { LazyShareLegal, lazyShareLegalLoader, LazyShareNonLegal, lazyShareNonLegalLoader } from "pages/Share";
import { FindDevice as FindDeviceLanding, findDeviceLoader as findDeviceLandingLoader } from "pages/Public/landings/FindDevice";
import { LostPhone as LostPhoneLanding, lostPhoneLoader as lostPhoneLandingLoader } from "pages/Public/landings/LostPhone";

export const routes = [
    {
        path: AppRoutes.HOME,
        element: <FindDeviceLanding />,
        loader: findDeviceLandingLoader,
    },
    {
        path: AppRoutes.HOME_LOST_PHONE,
        element: <LostPhoneLanding />,
        loader: lostPhoneLandingLoader,
    },
    {
        path: AppRoutes.SEARCH,
        element: <FakeSearch />,
        loader: fakeSearchLoader,
    },
    {
        path: AppRoutes.SIGN_UP,
        element: <SignUp />,
        loader: signUpLoader,
    },
    {
        path: `${AppRoutes.OAUTH}/:provider`,
        element: <OauthPage />,
        loader: oauthLoader,
    },
    {
        path: AppRoutes.PAYMENT,
        element: <Payment />,
        loader: PaymentLoader,
    },
    {
        path: AppRoutes.LOG_IN,
        element: <LogIn />,
        loader: logInLoader,
    },
    {
        path: AppRoutes.ACCOUNT,
        element: <AccountLayout />,
        children: [
            {
                index: true,
                element: <Navigate replace to={AppRoutes.FIND_DEVICE} />,
            },
            {
                path: AppRoutes.FIND_DEVICE,
                element: <FindDevice />,
                loader: findDeviceLoader,
            },
            {
                path: AppRoutes.LOST_PHONE,
                element: <LostPhone />,
                loader: lostPhoneLoader,
            },
            {
                path: AppRoutes.GENERATE_LINK,
                element: <GenerateLink />,
                loader: generateLinkLoader,
            },
        ],
    },
    {
        path: AppRoutes.FORGOT_PASSWORD,
        element: <ForgotPassword />,
        loader: forgotPasswordLoader,
    },
    {
        path: AppRoutes.NEW_PASSWORD,
        element: <NewPassword />,
        loader: newPasswordLoader,
    },
    {
        path: AppRoutes.SUCCESS,
        element: <SuccessPayment />,
        loader: successPaymentLoader,
    },
    {
        path: AppRoutes.DECLINE,
        element: <DeclinePayment />,
        loader: declinePaymentLoader,
    },
    {
        path: AppRoutes.SECURE_FLOW_RESULT,
        element: <PaymentResult />,
        loader: successPaymentLoader,
    },
    {
        path: AppRoutes.COOKIE_POLICY,
        element: <CookiePolicy />,
        loader: cookiePolicyLoader,
    },
    {
        path: AppRoutes.TERMS_OF_USE,
        element: <TermsOfUse />,
        loader: termsOfUseLoader,
    },
    {
        path: AppRoutes.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
        loader: privacyPolicyLoader,
    },
    {
        path: `${AppRoutes.SHARE_NON_LEGAL}/:locationId`,
        element: <LazyShareNonLegal />,
        loader: lazyShareNonLegalLoader,
    },
    {
        path: `${AppRoutes.SHARE_LEGAL}/:locationId`,
        element: <LazyShareLegal />,
        loader: lazyShareLegalLoader,
    },
    {
        path: `${AppRoutes.RESTRICTED_ACCESS}`,
        element: <RestrictedAccessByIp />,
        loader: restrictedAccessByIpLoader,
    },
];
