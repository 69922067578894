import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ReactComponent as CheckedIcon } from "assets/icons/checked-rounded-filled.svg";
import { useTranslation } from "hooks/useTranslation";
import { PlanCardProps, planVariants } from "./planVariants";
import { StyledCardDark, StyledCardLight } from "./Card.styled";
import { useCurrencyFormat } from "components/Payment/shared/hooks";

interface Props extends PlanCardProps {
    onClick?: () => void;
    formatLocaleCurrency: ReturnType<typeof useCurrencyFormat>["formatLocaleCurrency"];
}

const PlanVariantCard: FC<Props> = ({
    title,
    cardVariant = "light",
    sideStatus,
    trialPriceVat,
    duration,
    priceSubtitle,
    buttonText,
    checklist,
    onClick,
    formatLocaleCurrency,
}) => {
    const {
        t,
        detect: { isEnglishLanguage },
    } = useTranslation();

    const CardVariants = {
        dark: StyledCardDark,
        light: StyledCardLight,
    };

    const CardVariant = CardVariants[cardVariant];

    return (
        <CardVariant>
            <Stack>
                <Stack direction="row" justifyContent="space-between" sx={{ mb: "16px" }}>
                    <Typography variant="dmSansH4H5Semibold">{t(title)}</Typography>
                    <Typography className="side-status" sx={{ textTransform: typeof sideStatus === "string" ? "capitalize" : "unset" }}>
                        {typeof sideStatus === "string" ? t(sideStatus) : `${formatLocaleCurrency(sideStatus)} ${t("day")}`}
                    </Typography>
                </Stack>
                <Stack sx={{ mb: "8px", gap: "1px" }} direction="row" alignItems="flex-start">
                    <Typography variant="dmSansH3Semibold" sx={{ fontSize: !isEnglishLanguage ? "40px" : { xs: "42px", lmd: "48px" } }}>
                        {formatLocaleCurrency(trialPriceVat) ?? ""}
                    </Typography>
                    <Typography variant="dmSansH5Semibold" sx={{ mt: "17px", fontSize: !isEnglishLanguage ? "18px" : { xs: "20px", lmd: "23px" } }}>
                        /
                    </Typography>
                    <Typography
                        variant="dmSansH5Semibold"
                        sx={{
                            mt: "20px",
                            lineHeight: 1.2,
                            fontSize: !isEnglishLanguage ? "18px" : { xs: "20px", lmd: "23px" },
                            whiteSpace: isEnglishLanguage ? "nowrap" : undefined,
                        }}
                    >
                        {t(duration).toLowerCase()}
                    </Typography>
                </Stack>
                <Typography
                    variant="dmSansBody2Semibold"
                    sx={{
                        fontSize: "16px",
                        mb: "24px",
                    }}
                    className={"price-subtitle"}
                >
                    {t(priceSubtitle)}
                </Typography>
                <Button
                    onClick={onClick}
                    sx={{ color: "text.secondary", fontWeight: 800, fontSize: "17px", borderRadius: "12px", padding: "12px", mb: "42px" }}
                >
                    {t(buttonText)}
                </Button>
                <Stack gap="16px">
                    {checklist.map((item) => (
                        <Stack direction="row" alignItems="center" gap="8px" key={item}>
                            <CheckedIcon />
                            <Typography variant="dmSansBody1Regular">{t(item)}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </CardVariant>
    );
};

export const PlanVariantsList = ({
    onClick,
    formatLocaleCurrency,
}: {
    onClick: (trialPriceVat) => void;
    formatLocaleCurrency: Props["formatLocaleCurrency"];
}) => (
    <>
        {planVariants.map((props: PlanCardProps) => (
            <PlanVariantCard key={props.sideStatus} onClick={() => onClick(props)} formatLocaleCurrency={formatLocaleCurrency} {...props} />
        ))}
    </>
);
