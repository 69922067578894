export enum EndpointEnum {
    SIGNUP = "/auth/signup",
    SIGNIN = "/auth/signin",
    REFRESH_TOKEN = "/auth/refresh",
    USERS = "/users/data",
    LOCATIONS = "/locations",
    PHONE = "/phone",
    SUPPORT_TICKET = "/support/ticket",
    EXCHANGE_RATE = "/exchanger/latest",
}

export enum EndpointsPayment {
    USER_CREATE = "/users",
    USER_GET = "/users",
    USER_SUBSCRIPTION_GET = "/users/check-subscription",
    PAYMENT_ONE_CLICK_GET = "/users/payment-data",
    SUBSCRIPTION_PLANS_GET = "/subscription-plan",
    SUBSCRIPTION_TRANSACTION_CREATE = "/billing/ixopay/checkout/subscription",
    INAPP_TRANSACTION_CREATE = "/billing/ixopay/checkout/order",
    CREATE_PAYPAL_SUBSCRIPTION = "/subscriptions/create",
    APPLE_PAY_SESSION = "/billing/apple-pay/session",
}
