import { Box, Stack } from "@mui/material";
import { FC, ReactNode } from "react";

interface Props {
    children?: ReactNode;
}

export const PaywallWrapper: FC<Props> = ({ children }) => {
    return (
        <Stack
            sx={{
                position: "relative",
                minHeight: "90vh",
                pb: { xs: "32px", lmd: "142px" },
                background: "#000 url('/images/globe-horizon.png') no-repeat center top / auto 170vh",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(29, 32, 42, 0.90)",
                }}
            />
            {children}
        </Stack>
    );
};
