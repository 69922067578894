import { useAppData, useUserStore } from "providers/RootStoreProvider";
import Form from "components/form-components/Form";
import { ReactNode, useEffect, useRef } from "react";
import { IxopayFormProps, PaymentButtonTitleVariants } from "../../types";
import { useDisableScreen, useIxopayFormData } from "../../hooks";
import { filterFieldsText, PaywallType } from "../../utils";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import UAParser from "ua-parser-js";
import { zodResolver } from "@hookform/resolvers/zod";
import { ixopayFormSchema } from "schemas/ixopayForm";
import { Box, Stack, SxProps, Typography } from "@mui/material";
import { PAYMENT_PLAN_TYPE } from "constants/env.const";
import { PaymentPlanType } from "enums/payment.enum";
import { useTranslation } from "react-i18next";
import { IxopayFormSpy } from "./../IxopayFormSpy";
import FormControl from "components/form-components/FormControl";
import { LoadingButton, LoadingButtonProps } from "components/LoadingButton";
import { themeColors } from "constants/colors.const";
import { AppRoutes } from "enums/routes.enum";
import { Link } from "react-router-dom";
import { PaymentIconsBlock } from "../PaymentIconsBlock";
import { paymentIconsListMapPaywall } from "../../constants";
import { VATCountries } from "constants/VATCountries.const";
import { observer } from "mobx-react-lite";
import { LocationMapTextField } from "../LocationMapTextField.styled";
import { ApplePayButton } from "components/ApplePayButton";
import { SubscriptionSubscribeClickedProperties } from "services/ampli";
import { IxopayFormTextField, PaymentMethodsDivider } from "..";
import { GooglePayButton } from "components/GooglePayButton";

export interface IxopayFormInitialValues {
    card_holder?: string;
    expiry?: string;
}

const defaultValues: IxopayFormInitialValues = {
    card_holder: "",
    expiry: "",
};

interface Props extends IxopayFormProps {
    planExtraData?: {
        oldPrice: number;
        title: string;
    };
    submitButtonProps?: LoadingButtonProps;
    submitEventProps?: Omit<SubscriptionSubscribeClickedProperties, "success">;
    paywallType?: PaywallType;
    formTitle?: ReactNode;
    secureNotification?: ReactNode;
    textFieldComponent?: typeof IxopayFormTextField;
    sx?: SxProps;
}

const defaultPlanExtraData = {
    oldPrice: 9.99,
    title: "24h Trial",
};

export const FormWithDiscountAndDisclaimer = observer(
    ({
        data,
        planExtraData = defaultPlanExtraData,
        submitEventProps,
        secureNotification,
        paywallType = PaywallType.map,
        formTitle,
        submitButtonProps,
        textFieldComponent,
        formatLocaleCurrency,
        sx,
    }: Props) => {
        const { t } = useTranslation();
        const { amplitudeUserId, abTestData } = useAppData();
        const { userWebSession } = useUserStore();
        const paymentJsRef = useRef<any>(null);
        const formSpyRef = useRef<any>(null);

        const deviceInfo = new UAParser().getResult();
        const deviceBrand = deviceInfo?.device?.vendor;
        const isAppleDevice = deviceBrand === "Apple";

        const isAbApplePay = abTestData?.abTestApplePay === "1";
        const isApplepay = isAbApplePay && data?.plan && isAppleDevice;
        const isAbGooglePay = abTestData?.abTestGooglePay === "1";
        const isGooglePay = isAbGooglePay && data?.plan && (!isAppleDevice || !isApplepay);
        const { oldPrice, title } = planExtraData;

        const hasFormData = Boolean(data);
        const userData = userWebSession?.session?.user;
        const userEmail = userData?.email || "";
        const { showPaymentPlans, plan } = data || {};
        const priceValue = PAYMENT_PLAN_TYPE === PaymentPlanType.SUBSCRIPTION_WITH_TRIAL ? plan?.trialPrice : plan?.price;
        const isVATUser = VATCountries.some((country) => country.code === userData?.countryCode);
        const InputComponent = textFieldComponent ?? LocationMapTextField;

        const formattedTrialPrice = formatLocaleCurrency(priceValue);
        const formattedPrice = formatLocaleCurrency(plan?.price);
        const formattedOldPrice = formatLocaleCurrency(oldPrice);

        const { handleSubmit, spinnerLoading, loading } = useIxopayFormData({
            formSpyRef,
            paymentJsRef,
            hasFormData,
            data,
            userEmail,
            paywall: paywallType,
            submitEventProps,
        });

        useDisableScreen(loading);

        return (
            <>
                {amplitudeUserId ? (
                    <>
                        <Form
                            id="ixopay-payment-form"
                            className="ixopay-payment-form"
                            onSubmit={handleSubmit as SubmitHandler<Record<string, any>>}
                            mode="onSubmit"
                            defaultValues={defaultValues}
                            resolver={zodResolver(ixopayFormSchema)}
                        >
                            {({ formState, watch, setValue }: UseFormReturn) => {
                                const { errors: formErrors } = formState;

                                useEffect(() => {
                                    const subscription = watch((values) => {
                                        filterFieldsText(values, setValue);
                                    });
                                    return () => subscription.unsubscribe();
                                }, [watch]);

                                return (
                                    <Box
                                        sx={{
                                            "& .payment-methods-divider, & .form-title-wrap": {
                                                display: "none",
                                            },
                                            "&:has(.applepay-btn), &:has(.google-pay-button)": {
                                                "& .payment-methods-divider": {
                                                    display: "flex",
                                                },
                                                "& .form-title-wrap": {
                                                    display: "block",
                                                },
                                            },
                                            "& #gpay-button-online-api-id": { outline: "1px solid #efefef !important" },
                                            ...sx,
                                        }}
                                    >
                                        <Typography
                                            className="plan-name"
                                            variant="title3Bold"
                                            fontSize="20px"
                                            textAlign="center"
                                            sx={(theme) => ({
                                                display: "block",
                                                m: { xs: "0 0 .25rem", sm: "3.5rem 0 .25rem" },
                                                color: theme.palette.text.secondary,
                                            })}
                                        >
                                            {t(title)}
                                        </Typography>
                                        <Stack direction="row" gap=".5rem" justifyContent="center" className="price">
                                            <Box sx={{ position: "relative" }}>
                                                <Typography variant="body1CaptionBold" sx={(theme) => ({ color: theme.palette.text.disabled })}>
                                                    {formattedOldPrice}
                                                </Typography>
                                                <Box
                                                    sx={(theme) => ({
                                                        position: "absolute",
                                                        width: "85%",
                                                        height: "2px",
                                                        background: theme.palette.background.brightGreen,
                                                        top: "50%",
                                                        left: "8%",
                                                        transform: "rotate(-40deg)",
                                                    })}
                                                />
                                            </Box>
                                            <Typography variant="body1CaptionBold" fontSize="18px" sx={(theme) => ({ color: theme.palette.text.secondary })}>
                                                {formattedTrialPrice}
                                            </Typography>
                                        </Stack>
                                        {(isApplepay || isAbGooglePay) && (
                                            <Box sx={{ mt: "16px" }} className="alternative-providers-wrap">
                                                <Box className="form-title-wrap">{formTitle}</Box>
                                                {isApplepay && <ApplePayButton data={data} />}
                                                {isGooglePay && <GooglePayButton data={data} />}
                                                <PaymentMethodsDivider sx={{ mt: "12px" }} title={t("or pay by a card")} />
                                            </Box>
                                        )}
                                        <Stack
                                            padding="0 1rem"
                                            direction="column"
                                            flex={1}
                                            className="form-inputs-wrap"
                                            sx={{
                                                mb: "1.5rem",
                                                spacing: {
                                                    xs: 0,
                                                    sm: "15px",
                                                },
                                            }}
                                        >
                                            <IxopayFormSpy ref={formSpyRef} />

                                            <Box className="box--empty" />
                                            {showPaymentPlans && (
                                                <Typography variant="h3" alignSelf="center" align="center" maxWidth="260px">
                                                    {plan?.name}
                                                </Typography>
                                            )}
                                            <Stack
                                                direction="column"
                                                spacing="8px"
                                                sx={{ maxWidth: "410px", alignSelf: "center" }}
                                                className="form-fields-wrap"
                                            >
                                                <Stack direction="column" sx={{ width: "100%" }}>
                                                    <Stack direction="column" spacing="3px">
                                                        <Box id="number" sx={{ height: "50px", width: "100%" }} />
                                                    </Stack>
                                                    <FormControl
                                                        color="error"
                                                        error={Boolean(formErrors.number)}
                                                        errorMessage={(formErrors.number?.message || "") as string}
                                                    />
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    flex={1}
                                                    spacing="1rem"
                                                    alignItems="flex-end"
                                                    sx={{
                                                        height: "50px",
                                                        alignItems: "stretch",
                                                        gap: 0,
                                                    }}
                                                >
                                                    <Stack justifyContent="flex-end" flex={1}>
                                                        <InputComponent
                                                            className="ixopay-form-textfield"
                                                            name="expiry"
                                                            type="tel"
                                                            variant={"outlined"}
                                                            labelPlacement="title"
                                                            placeholder={t("MM/YY")}
                                                            disabled={loading}
                                                            showErrorText={true}
                                                            InputProps={{ inputMode: "numeric" }}
                                                            required
                                                            sx={{
                                                                border: "green",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        />
                                                    </Stack>

                                                    <Stack
                                                        direction="column"
                                                        flex={1}
                                                        sx={{
                                                            width: "100%",
                                                            "&:dir(rtl)": {
                                                                ml: 0,
                                                                mr: "1rem",
                                                            },
                                                        }}
                                                    >
                                                        <Stack direction="column" spacing="4px" mt="4px">
                                                            <Box id="cvv" sx={{ height: "50px", width: "100%" }} />
                                                        </Stack>
                                                        <FormControl
                                                            color="error"
                                                            error={Boolean(formErrors.cvv)}
                                                            errorMessage={(formErrors.cvv?.message || "") as string}
                                                        />
                                                    </Stack>
                                                </Stack>
                                                <InputComponent
                                                    className="ixopay-form-textfield"
                                                    name="card_holder"
                                                    type="text"
                                                    variant={"outlined"}
                                                    labelPlacement="title"
                                                    placeholder={t("Cardholder name")}
                                                    disabled={loading}
                                                    showErrorText={true}
                                                    required
                                                    sx={{
                                                        mt: { xs: 0, sm: "-8px" },
                                                    }}
                                                />
                                            </Stack>
                                        </Stack>
                                        {secureNotification}
                                        <LoadingButton
                                            className="button--primary btn ok anim"
                                            type="submit"
                                            variant="themePrimarySmall"
                                            disabled={loading} // !formState.isDirty
                                            fullWidth
                                            disableRipple
                                            disableElevation
                                            sx={{
                                                width: "100%",
                                                px: "unset",
                                                mb: "1rem",
                                                maxWidth: "100%",
                                                textTransform: "uppercase",

                                                backgroundColor: "background.brightGreen",
                                                ...submitButtonProps?.sx,
                                            }}
                                            loading={spinnerLoading}
                                            {...submitButtonProps}
                                        >
                                            <Typography variant="bodyInterExtraBold">
                                                {submitButtonProps?.children ?? t(PaymentButtonTitleVariants.START_TRIAL)}
                                            </Typography>
                                        </LoadingButton>
                                        <Box
                                            sx={(theme) => ({
                                                padding: { xs: 0, sm: "0 12px" },
                                                background: { xs: "none", sm: theme.palette.background.lightGrey },
                                                borderRadius: "24px",
                                                width: "100%",
                                            })}
                                        >
                                            <Stack
                                                direction="column"
                                                maxWidth="380px"
                                                alignItems="center"
                                                sx={{ textAlign: "left", padding: { xs: "0 1rem", sm: 0 } }}
                                            >
                                                <Typography
                                                    variant="smallInterRegular"
                                                    color={themeColors.text.lighterGrey}
                                                    sx={{ mb: "1rem", lineHeight: "20px" }}
                                                >
                                                    {submitButtonProps?.children
                                                        ? t("click_button_disclaimer", {
                                                              title:
                                                                  (submitButtonProps?.children as string).charAt(0).toUpperCase() +
                                                                  (submitButtonProps?.children as string).slice(1),
                                                          })
                                                        : t("By clicking “Continue”, you accept our")}{" "}
                                                    <Link to={AppRoutes.TERMS_OF_USE} style={{ color: themeColors.background.brightGreen }}>
                                                        {t("Terms of Use_disclaimer")}
                                                    </Link>{" "}
                                                    {t("and")}{" "}
                                                    <Link to={AppRoutes.PRIVACY_POLICY} style={{ color: themeColors.background.brightGreen }}>
                                                        {t("Privacy Notice")}
                                                    </Link>
                                                </Typography>
                                                <PaymentIconsBlock iconsList={paymentIconsListMapPaywall} />
                                                <Typography
                                                    variant="smallThin"
                                                    className="disclaimer"
                                                    sx={{
                                                        lineHeight: "16px",
                                                        fontSize: { xs: "11px", sm: "13px" },
                                                        color: themeColors.background.whiteOpacity4,
                                                        mt: "24px",
                                                    }}
                                                >
                                                    {t("Payment_form_descr_p1")}{" "}
                                                    <span style={{ fontWeight: 500 }}>
                                                        {t("Payment_form_descr_p2", {
                                                            price: formattedPrice,
                                                        })}
                                                    </span>{" "}
                                                    {
                                                        // TODO replace hardcode if subscription duration changes
                                                        t("every month")
                                                    }{" "}
                                                    {t("Payment_form_descr_p4")}{" "}
                                                    <Link
                                                        to={{ pathname: "/terms-of-use", hash: "subscription-terms" }}
                                                        style={{
                                                            textDecoration: "underline",
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        {t("Subscription Terms")}.
                                                    </Link>{" "}
                                                    {isVATUser && t("Payment_form_descr_p6")}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </Box>
                                );
                            }}
                        </Form>
                    </>
                ) : null}
                <Box className="ixopay-3ds-flow" sx={{ marginTop: "0px !important" }} />
            </>
        );
    }
);
