import { FC } from "react";
import { NavLink } from "react-router-dom";
import { Stack, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "hooks/useTranslation";
import { menuList } from "./menuList.const";
import { FeatureLinksContainer } from "./Menu.styled";

const linkProps = (languages: ReturnType<typeof useTranslation>["detect"]) => {
    const isOverlappedLanguages = languages.isFrenchLanguage || languages.isSpanishLanguage || languages.isGermanLanguage || languages.isPortugueseLanguage;

    return {
        sm: {
            typography: {
                variant: "footnoteRegular",
                width: isOverlappedLanguages ? "100px" : "70px",
            },
            icon: {},
        },
        md: {
            typography: {
                variant: "dmSansBody2Regular",
                width: isOverlappedLanguages ? "110px" : "80px",
            },
            icon: {
                fontSize: "large",
            },
        },
    } as const;
};

const FeatureLinks: FC<Partial<Record<"variant", "sm" | "md">>> = ({ variant = "md" }) => {
    const { t, detect } = useTranslation();
    const feature = linkProps(detect)[variant];

    return (
        <FeatureLinksContainer>
            {menuList.map(({ title, path, icon }) => (
                <NavLink
                    key={path}
                    to={path}
                    style={({ isActive }) => ({
                        pointerEvents: isActive ? "none" : "initial",
                    })}
                >
                    {({ isActive }) => (
                        <Stack
                            alignItems="center"
                            gap="2px"
                            sx={{
                                flexDirection: { lmd: "row" },
                            }}
                        >
                            <SvgIcon
                                {...feature.icon}
                                sx={(theme) => ({
                                    color: isActive ? "#865EF6" : theme.palette.brandColors.brandLightgrey,
                                })}
                            >
                                {icon}
                            </SvgIcon>
                            <Typography {...feature.typography} align="center" color={isActive ? "text.secondary" : "text.disabled"}>
                                {t(title)}
                            </Typography>
                        </Stack>
                    )}
                </NavLink>
            ))}
        </FeatureLinksContainer>
    );
};

export default FeatureLinks;
