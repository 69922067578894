import { ReactComponent as StarFilled } from "assets/icons/star-filled.svg";
import { ReactComponent as HalfStarFilled } from "assets/icons/half-star-filled.svg";
import { ReactComponent as StarEmpty } from "assets/icons/star-empty.svg";
import { Stack } from "@mui/material";

const renderStars = (rating) => {
    return Array.from({ length: 5 }).map((_, index) => {
        const currentIndex = index + 1;
        let Star;

        if (currentIndex <= rating) {
            Star = StarFilled;
        } else if (currentIndex - 1 < rating && currentIndex > rating) {
            Star = HalfStarFilled;
        } else {
            Star = StarEmpty;
        }

        return <Star key={index} />;
    });
};

export const StarsRating = ({ rating }: { rating: number }) => (
    <Stack direction="row" gap="4px" sx={{ mb: "16px" }}>
        {renderStars(rating)}
    </Stack>
);
