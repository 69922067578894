import { useUserStore } from "providers/RootStoreProvider";
import { loadFromStorage } from "services/storage";

export const useDetectAttributionSentState = () => {
    const { userWebSession, userProfile } = useUserStore();

    const userProfileAmpliId = userProfile?.amplitudeSessionId;
    const webAttributionSent = userWebSession?.session?.webAttributionSent;
    const persistedUsid = loadFromStorage("attributionSentId");

    const detectAttributionSentState = (usid: string) => {
        const sendInitialAttributionEvent = (!persistedUsid || persistedUsid !== usid) && !userProfileAmpliId;
        const isAttributionSent = webAttributionSent || !sendInitialAttributionEvent || (webAttributionSent && !userProfileAmpliId);

        return !!isAttributionSent;
    };

    return detectAttributionSentState;
};
