import { lazy, Suspense } from "react";
import { PageFallback } from "components/PageFallback";

let GenerateLink = lazy(() => import("./GenerateLink"));

export async function lazyGenerateLinkLoader() {
    const componentModule = await import("./GenerateLink");
    GenerateLink = componentModule.default as any;

    return null;
}

export function LazyGenerateLink() {
    return (
        <Suspense fallback={<PageFallback />}>
            <GenerateLink />
        </Suspense>
    );
}
