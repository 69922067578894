import { FC, useRef } from "react";
import { isProductionHost } from "helpers/isProductionHost";
import { MakeSubscriptionTransactionParams, makeSubscriptionTransaction } from "http-client/payment.client";
import { observer } from "mobx-react-lite";
import { createSearchParams, useNavigate } from "react-router-dom";
import GooglePay from "@google-pay/button-react";
import { GPAY_MERCHANT_ID, PROJECT_NAME } from "constants/env.const";
import { useAppData } from "providers/RootStoreProvider";
import { AppRoutes } from "enums/routes.enum";
import { IxopayFormData } from "components/Payment/shared/types";
import { handleAmpliLoaded } from "helpers/handleAmpliLoaded";
import { ampli } from "services/ampli";
import { useHandleTransactionResponse } from "components/Payment/shared/hooks";
import { getPaymentRedirectUrl } from "constants/getPaymentRedirectUrl.const";
import { use3DSBrowserInfo } from "components/Payment/shared/hooks/use3DSBrowserInfo";

interface Props {
    data: IxopayFormData;
}

export const GooglePayButton: FC<Props> = observer(({ data }) => {
    const navigate = useNavigate();
    const { amplitudeUserId } = useAppData();
    const handleTransactionResponse = useHandleTransactionResponse();
    const get3DSecureBrowserInfo = use3DSBrowserInfo();
    const environment = isProductionHost() ? "PRODUCTION" : "TEST";
    const { plan, declineQuery } = data;
    const modalOpenedRef = useRef(false);
    const paymentRequest: google.payments.api.PaymentDataRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
        callbackIntents: ["PAYMENT_AUTHORIZATION"],
        allowedPaymentMethods: [
            {
                type: "CARD",
                parameters: {
                    allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                    allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"],
                },
                tokenizationSpecification: {
                    type: "PAYMENT_GATEWAY",
                    parameters: {
                        gateway: "ixopay",
                        gatewayMerchantId: GPAY_MERCHANT_ID,
                    },
                },
            },
        ],
        merchantInfo: {
            merchantId: GPAY_MERCHANT_ID,
            merchantName: "Geomobile",
        },
        transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: plan.name,
            totalPrice: plan.trialPrice?.toFixed(2),
            currencyCode: "USD",
            countryCode: "EE",
        },
    };

    const handlePaymentAuthorized = async (paymentData: google.payments.api.PaymentData): Promise<google.payments.api.PaymentAuthorizationResult> => {
        let transactionState: google.payments.api.TransactionState = "SUCCESS";
        try {
            const token = paymentData.paymentMethodData.tokenizationData.token;
            const ccToken = `googlepay:${token}`;

            const redirect = getPaymentRedirectUrl("googlepay");
            const successUrl = redirect.success;
            const errorUrl = redirect.error;
            const threeDSecureData = get3DSecureBrowserInfo();

            const params: MakeSubscriptionTransactionParams = {
                userAmpliId: amplitudeUserId,
                ccToken,
                project: PROJECT_NAME,
                subscriptionPlanId: plan.id || "",
                applicationContext: {
                    successUrl,
                    cancelUrl: errorUrl,
                    errorUrl,
                },
                customer: {
                    firstName: "", //TODO: add first name
                    lastName: "", //TODO: add last name
                },
                threeDSecureData,
            };

            const transactionResponse = await makeSubscriptionTransaction(params);

            await handleTransactionResponse({ transactionResponse, data, provider: "googlepay" }).catch();
        } catch (error) {
            transactionState = "ERROR";
            console.error("Error processing Google Pay payment:", error);
            setTimeout(() => {
                navigate({ pathname: AppRoutes.DECLINE, search: createSearchParams({ ...declineQuery, provider: "googlepay" }).toString() });
            }, 800);
        }
        return { transactionState };
    };

    const handleClick = () => {
        if (!modalOpenedRef.current) {
            modalOpenedRef.current = true;
            handleAmpliLoaded(() => ampli.googlePayButtonClicked());
        }
    };

    const handleCancel = () => {
        modalOpenedRef.current = false;
    };

    return (
        <GooglePay
            style={{
                height: "56px",
                width: "100%",
            }}
            className="google-pay-button"
            buttonRadius={8}
            buttonType="plain"
            buttonSizeMode="fill"
            environment={environment}
            paymentRequest={paymentRequest}
            onPaymentAuthorized={handlePaymentAuthorized}
            onClick={handleClick}
            onCancel={handleCancel}
        />
    );
});
