export const getUTCTimezoneOffsetInHours = () => {
    const date = new Date();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Use Intl.DateTimeFormat to get the offset for the specified timezone
    const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: timezone,
        hour: "numeric",
        hour12: false,
        timeZoneName: "short",
    });

    // Format the date to get the timezone offset
    const parts = formatter.formatToParts(date);
    const timeZonePart = parts.find((part) => part.type === "timeZoneName");
    const offsetString = timeZonePart?.value;

    const offsetMatch = offsetString?.match(/GMT([+-]\d+)/);

    const offsetHours = offsetMatch ? parseInt(offsetMatch[1], 10) : 0;
    return offsetHours;
};
