import { FC } from "react";
import { PlanVariantCard } from "./PlanVariantCard";
import { useTranslation } from "hooks/useTranslation";

interface Props {
    chosenPlan?: Record<string, any>;
    onChange: ({ priceVat, trialPriceVat, oldPrice, title }: { priceVat: number; trialPriceVat: number; oldPrice: number; title: string }) => void;
    formatLocaleCurrency: (amount: number) => string | undefined;
    planVariants: Record<string, any>[];
}

export const PlanVariantsList: FC<Props> = ({ chosenPlan, onChange, formatLocaleCurrency, planVariants }) => {
    const { t } = useTranslation();

    return (
        <>
            {planVariants.map(
                ({ title, subtitle, specialPrice, specialPriceAddOn, badgeTitle, accentColor, priceVat, trialPriceVat, oldPrice, activePriceColor }) => (
                    <PlanVariantCard
                        key={title}
                        title={t(title)}
                        subtitle={typeof subtitle === "string" ? t(subtitle) : formatLocaleCurrency(subtitle) || ""}
                        specialPrice={formatLocaleCurrency(specialPrice) || ""}
                        specialPriceAddOn={t(specialPriceAddOn)}
                        badgeTitle={t(badgeTitle)}
                        isActive={chosenPlan?.trialPriceVat === trialPriceVat && chosenPlan?.priceVat === priceVat}
                        sx={{ mb: "18px" }}
                        accentColor={accentColor}
                        activePriceColor={activePriceColor}
                        onClick={() => onChange({ priceVat, trialPriceVat, oldPrice, title })}
                    />
                )
            )}
        </>
    );
};
